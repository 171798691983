import { TextareaHTMLAttributes, ForwardedRef, forwardRef } from "react";
import InputError, { ErrorStyle } from "../InputError";
import InputLabel from "../InputLabel";
import styles from "./styles.module.scss";

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string | undefined,
  error?: string | undefined,
  errorStyle?: ErrorStyle,
};

export type TextareaRef = ForwardedRef<HTMLTextAreaElement>

const Textarea = ({ className, label, error, errorStyle, disabled, ...props }: TextareaProps, ref: TextareaRef) => {
  return (
    <label className={`${styles.textareabox} ${className ?? ""}`}>
      {label && <InputLabel className={styles.textareaLabel} disabled={disabled}>{label}</InputLabel>}
      <textarea ref={ref} className={`${styles.textarea} ${error !== undefined ? styles.error : ""}`} disabled={disabled} {...props}></textarea>
      <div className={styles.errorContainer}>
        <InputError errorStyle={errorStyle}>{error}</InputError>
      </div>
    </label>
  );
};

export default forwardRef(Textarea);
